<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>
            ДОО
          </template>
          <template v-slot:toolbar>
          </template>
          <template v-slot:body>
            <table class="table table-bordered">
              <thead class="thead-light">
              <tr>
                <th class="text-left">{{ $t("schools.list.nameRu") }}</th>
                <th class="text-left">{{ $t("schools.list.nameKg") }}</th>
                <th class="text-left">{{ $t("schools.list.region") }}</th>
                <th class="text-left">{{ $t("schools.list.address") }}</th>
                <th class="text-left">
                  {{ $t("schools.list.contactPhone") }}
                </th>
                <th class="text-left"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in schools" :key="item.id">
                <td>{{ item.nameRu }}</td>
                <td>{{ item.nameKg }}</td>
                <td>{{ item.regionName }}</td>
                <td>{{ item.address }}</td>
                <td>{{ item.contactPhone }}</td>
                <td>
                  <b-button @click="editSchool(item)" variant="primary">
                    {{ $t("common.edit") }}
                  </b-button>
                </td>
              </tr>
              </tbody>
            </table>

            <div>
              <b-modal id="preschool-modal" ref="preschool-modal">
                <template v-slot:modal-title>
                  {{ $t("common.new") }}
                </template>
                <b-form>
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("schools.list.nameRu") }}
                    </template>
                    <b-form-input
                      v-model="form.nameRu"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("schools.list.nameKg") }}
                    </template>
                    <b-form-input
                      v-model="form.nameKg"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("schools.list.region") }}
                    </template>
                    <b-form-select
                        v-model="form.regionId"
                        :options="regions"
                    ></b-form-select>
                  </b-form-group>
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("schools.list.address") }}
                    </template>
                    <b-form-input
                      v-model="form.address"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("schools.list.contactPhone") }}
                    </template>
                    <b-form-input
                      v-model="form.contactPhone"
                    ></b-form-input>
                  </b-form-group>
                </b-form>
                <template v-slot:modal-footer>
                  <b-button type="submit" @click="onSubmit" variant="primary">{{
                    $t("common.save")
                  }}</b-button>
                  <b-button type="reset" @click="onReset" variant="danger">{{
                    $t("common.cancel")
                  }}</b-button>
                </template>
              </b-modal>
            </div>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<style>
  td>button{
    margin-right: 10px;
  }
</style>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";

export default {
  name: "PreschoolAdminDashboard",
  components: {
    KTPortlet
  },
  data() {
    return {
      schools: null,
      dialog: true,
      regions:[],
      form: {
        nameRu: "",
        nameKg: "",
        address: "",
        contactPhone: "",
        regionId: ""
      }
    };
  },
  mounted() {
    this.reloadData();
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      let $this = this;
      ApiService.postSecured("preschoolAdmin/save", this.form)
        .then(function() {
          $this.$refs["preschool-modal"].hide();
          $this.reloadData();
          $this.cleanModel();
        })
        .catch(({ response }) => {
          console.log(response);
          $this.reloadData();
        });
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form = {};

      this.$refs["preschool-modal"].hide();
    },
    reloadData() {
      ApiService.querySecured("preschoolAdmin/preschools")
        .then(({ data }) => {
          this.schools = data.list;
        })
        .catch(({ response }) => {
          console.log(response);
        });

      ApiService.querySecured("dictionaries/regions")
          .then(({ data }) => {
            this.regions = data.dictionary;
          })
          .catch(({ response }) => {
            console.log(response);
          });
    },
    cleanModel() {
      this.form = {};
    },
    newSchool() {
      this.form = {};
      this.$refs["preschool-modal"].show();
    },
    editSchool(school) {
      this.form = school;
      this.$refs["preschool-modal"].show();
    }
  }
};
</script>
